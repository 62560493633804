import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext';
import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // 7 jours

  // Sauvegarder le panier dans localStorage avec un timestamp
  const saveToLocalStorage = (cartItems) => {
    const data = {
      cartItems,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem('cartItems', JSON.stringify(data));
  };

  // Charger le panier depuis localStorage si valide
  const loadFromLocalStorage = () => {
    const data = JSON.parse(localStorage.getItem('cartItems'));
    if (data) {
      const now = new Date().getTime();
      if (now - data.timestamp < EXPIRATION_TIME) {
        return data.cartItems;
      }
    }
    return [];
  };

  // Chargement initial des données du panier
  useEffect(() => {
    const fetchCart = async () => {
      setLoading(true);

      if (currentUser) {
        const localCart = loadFromLocalStorage();
        const userDocRef = doc(db, 'users', currentUser.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const mergedCart = [...localCart, ...(userData.cart || [])]
              .reduce((acc, item) => {
                const existing = acc.find(cartItem => cartItem.id === item.id);
                if (existing) {
                  existing.quantity += item.quantity;
                } else {
                  acc.push(item);
                }
                return acc;
              }, []);
            await setDoc(userDocRef, { cart: mergedCart }, { merge: true });
            setCartItems(mergedCart);
            localStorage.removeItem('cartItems'); // Nettoyer localStorage après synchronisation
          } else {
            await setDoc(userDocRef, { cart: localCart });
            setCartItems(localCart);
            localStorage.removeItem('cartItems');
          }
        } catch (error) {
          console.error('Failed to fetch cart from Firestore:', error);
        }
      } else {
        // Charger depuis localStorage pour les utilisateurs non connectés
        const storedCartItems = loadFromLocalStorage();
        setCartItems(storedCartItems);
      }

      setLoading(false);
    };

    fetchCart();
  }, [currentUser]);

  // Ajouter un article au panier
  const addToCart = async (item) => {
    if (!currentUser) {
      const storedCartItems = loadFromLocalStorage();
      const existingItemIndex = storedCartItems.findIndex(cartItem => cartItem.id === item.id);

      if (existingItemIndex >= 0) {
        storedCartItems[existingItemIndex].quantity += 1;
      } else {
        storedCartItems.push({ ...item, quantity: 1 });
      }

      saveToLocalStorage(storedCartItems);
      setCartItems(storedCartItems);
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      let cart = [];
      if (userDoc.exists()) {
        const userData = userDoc.data();
        cart = userData.cart || [];
      }

      const existingItemIndex = cart.findIndex(cartItem => cartItem.id === item.id);
      if (existingItemIndex >= 0) {
        cart[existingItemIndex].quantity += 1;
      } else {
        cart.push({ ...item, quantity: 1 });
      }

      await setDoc(userDocRef, { cart }, { merge: true });
      setCartItems(cart);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  // Supprimer un article du panier
  const removeFromCart = async (itemId) => {
    if (!currentUser) {
      const storedCartItems = loadFromLocalStorage();
      const updatedCart = storedCartItems.filter(item => item.id !== itemId);
      saveToLocalStorage(updatedCart);
      setCartItems(updatedCart);
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedCart = userData.cart.filter(item => item.id !== itemId);
        await updateDoc(userDocRef, { cart: updatedCart });
        setCartItems(updatedCart);
      }
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  // Mettre à jour la quantité d'un article
  const updateQuantity = async (itemId, quantity) => {
    if (!currentUser || quantity < 1) return;

    const userDocRef = doc(db, 'users', currentUser.uid);
    try {
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedCart = userData.cart.map(item =>
          item.id === itemId ? { ...item, quantity } : item
        );
        await updateDoc(userDocRef, { cart: updatedCart });
        setCartItems(updatedCart);
      }
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, updateQuantity, loading }}>
      {children}
    </CartContext.Provider>
  );
};
