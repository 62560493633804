import React, { useState, useContext } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Trash } from 'lucide-react';
import { deleteUser, getAuth, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { db } from '../firebase/firebase';
import { collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { AuthContext } from '../features/authContext';
import { CircleArrowLeft } from 'lucide-react';
import { useNavigate } from'react-router-dom';

const SettingsScreen = ({ history }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("Erreur: Utilisateur non trouvé.");
      return;
    }

    try {
      await deleteUserData(user.uid);
      await deleteUser(user);
      alert("Succès: Votre compte a été supprimé avec succès.");
      history.replace('/auth');
    } catch (error) {
      console.error("Erreur lors de la suppression du compte:", error);
      alert(`Erreur lors de la suppression du compte. Veuillez réessayer. \n${error.message}`);
    }
  };

  const deleteUserData = async (userId) => {
    const batch = writeBatch(db);
    const collections = ['profiles', 'commandes', 'messages', 'conversations', 'mesures', 'notifications', 'patterns', 'withdrawals'];

    for (const col of collections) {
      const q = query(collection(db, col), where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    await batch.commit();
  };

  const handleReauthentication = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    try {
      await reauthenticateWithCredential(user, credential);
      console.log("Re-authentication successful");
      handleDeleteAccount();
    } catch (error) {
      console.error("Erreur lors de la ré-authentification:", error);
      alert(`Erreur lors de la ré-authentification. Veuillez réessayer. \n${error.message}`);
    }
  };

  return (
    <Container>
      <IconButton onClick={() => navigate(-1)} style={styles.backButton}>
        <CircleArrowLeft/>
      </IconButton>
      <Typography variant="h4" gutterBottom>
        Paramètres du Compte
      </Typography>
      <Box display="flex" alignItems="center" mb={2} p={2} bgcolor="#f8d7da" borderRadius={2}>
        <Typography variant="body1" flexGrow={1}>
          Supprimer Mon Compte
        </Typography>
        <IconButton color="secondary" onClick={() => setModalOpen(true)}>
          <Trash />
        </IconButton>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>Supprimer Mon Compte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible. Toutes vos données seront supprimées.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Mot de passe"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleReauthentication} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const styles = {
  backButton: {
    position: 'absolute',
    left: 10,
    top: 10,
  }
}

export default SettingsScreen;
